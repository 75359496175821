<template>
  <section id="Sustainability">
    <div class="left">
      <div class="title">{{ sections[0].title }}</div>
      <div class="items">
        <div class="item" v-for="item in sections[0].items" :key="item.label">
          <div class="big">{{ item.value }}</div>
          <div class="small">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="half">
          <div class="group">
            <div class="title">{{ sections[1].title }}</div>
            <div class="item">
              <div class="big">{{ sections[1].items[0].value }}</div>
              <div class="small">{{ sections[1].items[0].label }}</div>
            </div>
          </div>
          <div class="group">
            <div class="title">{{ sections[2].title }}</div>
            <div class="item">
              <div class="big">{{ sections[2].items[0].value }}</div>
              <div class="small">{{ sections[2].items[0].label }}</div>
            </div>
          </div>
        </div>
        <div class="half">
          <div class="three">
            <div class="title">{{ sections[3].title }}</div>
            <div class="set">
              <div
                class="item triple"
                v-for="item in sections[3].items"
                :key="item.label"
              >
                <div class="big">{{ item.value }}</div>
                <div class="small">{{ item.label }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="half">
          <div class="group">
            <div class="title">{{ sections[4].title }}</div>
            <div class="item">
              <div class="big">{{ sections[4].items[0].value }}</div>
              <div class="small" v-html="sections[4].items[0].label" />
            </div>
          </div>
          <div class="group">
            <div class="title">{{ sections[5].title }}</div>
            <div class="item">
              <div class="big">{{ sections[5].items[0].value }}</div>
              <div class="small" v-html="sections[5].items[0].label" />
            </div>
          </div>
        </div>
        <div class="half">
          <div class="group">
            <div class="title">{{ sections[6].title }}</div>
            <div class="item">
              <div class="big">{{ sections[6].items[0].value }}</div>
              <div class="small" v-html="sections[6].items[0].label" />
            </div>
          </div>
          <div class="group">
            <div class="title">{{ sections[7].title }}</div>
            <div class="item">
              <div class="big">{{ sections[7].items[0].value }}</div>
              <div class="small" v-html="sections[7].items[0].label" />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="title">{{ sections[8].title }}</div>
        <div class="timeline">
          <div
            class="item"
            v-for="item in sections[8].timeline"
            :key="item.text"
          >
            <div class="date" v-html="item.date" />
            <div class="line">
              <div class="dot" />
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Sustainability",
  data() {
    return {
      sections: [
        {
          title: "Waste Diversion",
          items: [
            {
              label:
                "pounds of food scraps sent to local livestock farms monthly.",
              value: "70.5K",
            },
            {
              label:
                "watts of energy created from Cigarette to Energy collection program.",
              value: "69.7K",
            },
            {
              label:
                "pounds of concessionaire food donated to local food banks monthly.",
              value: "7.3K",
            },
            { label: "pounds of cardboard recycled monthly.", value: "15.5K" },
            {
              label:
                "pounds of rubber recycled a month through Rubber Turf Pellet Recycling Program.",
              value: "3.7K",
            },
          ],
        },
        {
          title: "Local Innovation",
          items: [
            {
              label: "of old sod used as livestock bedding.",
              value: "994.26 tons",
            },
          ],
        },
        {
          title: "Biomass Machine",
          items: [
            {
              label:
                "of food scraps, paper products, and field grass clippings are processed with a 24-hour period.",
              value: "650 lbs",
            },
          ],
        },
        {
          title: "Green Purchasing",
          items: [
            {
              label:
                "of the electronics no longer needed on-site are properly e-wasted or recycled.",
              value: "100%",
            },
            {
              label: "of batteries used in stadium offices are rechargeable.",
              value: "100%",
            },
            {
              label: "of food & beverage is either organic or locally sourced.",
              value: "Over 17%",
            },
          ],
        },
        {
          title: "Energy Efficiency",
          items: [
            {
              label:
                "powered by Nevada-sourced renewable energy.<br><br>Stadium roof is composed of ETFE (Ethylene Tetrafluoroethylene).",
              value: "100%",
            },
          ],
        },
        {
          title: "Water Efficiency",
          items: [
            {
              label:
                "gallons of water saved annually.<br><br>Allegiant Stadium maintains green landscaping practices.",
              value: "535 Million",
            },
          ],
        },
        {
          title: "Lost & Found",
          items: [
            {
              label:
                "of items donated every 1 to 2 months.<br><br>The stadium donates all unclaimed items to local nonprofits.",
              value: "100 LBS",
            },
          ],
        },
        {
          title: "Green Transportation",
          items: [
            {
              label:
                "are located at three different locations at Allegiant Stadium.<br><br>16 electric charging stations that can charge up to 32 vehicles.",
              value: "35",
            },
          ],
        },
        {
          title: "Accolades & Awards",
          timeline: [
            {
              date: "May<br><span>2022</span>",
              text: "Allegiant Stadium hosted a panel regarding waste diversion and fan engagement at WasteExpo 2022.",
            },
            {
              date: "Jul<br><span>2022</span>",
              text: "Nominated by The Stadium Business Summit for theSustainability and Community Award.",
            },
            {
              date: "Mar<br><span>2023</span>",
              text: "Allegiant Stadium records a comprehensive waste diversion webinar with Green Sports Alliance.",
            },
            {
              date: "Jun<br><span>2023</span>",
              text: `Allegiant Stadium wins "Play to Zero Waste Award" for their waste diversion efforts at Green Sports Alliance.`,
            },
            {
              date: "Oct<br><span>2023</span>",
              text: "Allegiant Stadium becomes 100% powered by Nevada-sourced renewable energy.",
            },
            {
              date: "Jun<br><span>2024</span>",
              text: `Allegiant Stadium wins "Play to Zero Waste Award" and "Play to Zero Energy Champion Award" at Green Sports Alliance.`,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#Sustainability {
  position: relative;
  padding: 160px 69px 50px;
  min-height: 100vh;
  width: 100%;
  background: url("~@/assets/images/Sustainability/bg-sustainability.jpg")
    no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  @media (max-width: 992px) {
    padding: 160px 50px 50px;
  }
  @media (max-width: 768px) {
    padding: 160px 35px 50px;
  }
  @media (max-width: 576px) {
    padding: 160px 15px 50px;
  }
  .left {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: calc(20% - 20px);
    @media (max-width: 1100px) {
      width: 100%;
      margin-bottom: 35px;
    }
    .items {
      display: flex;
      flex-direction: column;
      gap: 35px;
      width: 100%;
      @media (max-width: 1100px) {
        flex-direction: row;
        width: 100%;
      }
      @media (max-width: 576px) {
        flex-wrap: wrap;
        .item {
          width: calc(50% - 20px);
        }
      }
      @media (max-width: 450px) {
        .item {
          width: 100%;
        }
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: calc(80% - 20px);
    @media (max-width: 1100px) {
      width: 100%;
    }
    .top,
    .middle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      .timeline {
        display: flex;
        flex-direction: row;
        width: 100%;
        color: #ffffff;
        @media (max-width: 700px) {
          flex-wrap: wrap;
          gap: 15px 0;
        }
        .item {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: calc(100% / 6);
          @media (max-width: 700px) {
            width: calc(100% / 3);
          }
          @media (max-width: 576px) {
            width: calc(50%);
          }
          @media (max-width: 450px) {
            width: 100%;
          }
          .date {
            font-family: "ArtegraSans-Light";
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0;
            text-transform: uppercase;
            span {
              font-family: "ArtegraSans-Bold";
            }
          }
          .line {
            position: relative;
            height: 1px;
            width: 100%;
            background-color: #ffffff;
            @media (max-width: 700px) {
              display: none;
            }
            .dot {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 11px;
              width: 11px;
              border-radius: 50%;
              border: 1px solid #ffd200;
              background-color: #ff8300;
            }
          }
          .text {
            font-family: "ArtegraSans-Regular";
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 0;
            padding-right: 35px;
          }
        }
      }
    }
    .half {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: calc(50% - 20px);
      @media (max-width: 576px) {
        width: 100%;
        margin-bottom: 35px;
      }
      @media (max-width: 450px) {
        flex-direction: column;
        gap: 35px;
      }
    }
    .three {
      display: flex;
      flex-direction: column;
      gap: 35px;
      .set {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 450px) {
          flex-direction: column;
          gap: 25px;
        }
        .triple {
          width: calc((100% / 3) - 10px);
          @media (max-width: 450px) {
            width: 100%;
          }
        }
      }
    }
  }
  .group {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: calc(50% - 10px);
    @media (max-width: 450px) {
      width: 100%;
    }
  }
  .title {
    font-family: "ArtegraSans-Bold";
    font-size: 12px;
    letter-spacing: 0.8px;
    font-weight: 700;
    color: #ff8300;
    text-transform: uppercase;
  }
  .item {
    display: flex;
    flex-direction: column;
    .big {
      font-family: "ArtegraSans-Light";
      font-size: 31px;
      line-height: 1.2;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 200;
    }
    .small {
      font-family: "ArtegraSans-Regular";
      font-size: 12px;
      color: #ffffff;
      line-height: 16px;
      font-weight: 400;
    }
  }
}
</style>